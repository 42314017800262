@import "./_colours";

//
// Sport Connect global variables
//

// SDS variables
$SDS-page-max-width: 1540px; // Max width of web page
$SDS-page-content-max-width: 1140px; // Max width of content within web page - static content
$SDS-page-content-admin-max-width: 1000px; // Max width of content within web page - admin content

$SDS-color-background: $asc-blue-0;
$SDS-color-foreground-action: $asc-blue-0;
$SDS-color-foreground-focus: $asc-yellow-0;
$SDS-color-foreground-text: $sds-navy;
$SDS-font: Simplon, Helvetica Neue, Arial, sans-serif;

$SDS-border-radius: 5px;