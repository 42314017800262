.sds-pagination {
  display: flex;
  margin-top: 1rem;

  .sds-pagination__separator {
    &:after {
      content: '|';
    }
  }

  .sds-pagination__recordCount, .sds-pagination__pageCount {
    min-width: 6rem;
  }

  .sds-pagination__left {
    text-align: left;
    flex: 0;
    display: inline-block;
    padding-top: 0.9rem;
    font-size: 0.9rem;
  }

  .sds-pagination__centre {
    flex: 1;
    display: inline-block;
    text-align: center;
  }

  .sds-pagination__right {
    text-align: right;
    padding-top: 0.9rem;
    font-size: 0.9rem;
    display: inline-block;
    flex: 0;
  }
}