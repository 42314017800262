$asc-blue-0: #0f0a30;
$asc-blue-1: #153f8f;
$asc-blue-2: #497fc1;
$asc-blue-3: #46a0d9;
$asc-blue-4: #71caf2;
$asc-blue-5: #57a99c;
$asc-blue-6: #3c7c7a;
$asc-blue-7: #004d5a;


$asc-green-0: #00291f;
$asc-green-1: #339838;
$asc-green-2: #3ea838;
$asc-green-3: #89bd23;
$asc-green-4: #d3d800;

$asc-yellow-0: #fac500;
$asc-yellow-1: #ffd900;
$asc-yellow-2: #ffed00;

$asc-red-0: #86141d;
$asc-red-1: #ce2e24;
$asc-red-2: #dd5d08;
$asc-red-3: #f6a400;

$white: #fff;
$black: #000;
$grey-0: #777;
$grey-1: #ccc;
$grey-2: #f1f1f2;


// Corporate SportAUS colours
$sds-navy: #080533;
$sds-gold: #ffc709;
$sds-yellow: #ffdd00;
$sds-blue-secondary: #64cbe8;
$sds-blue-tertiary: #007db4;
$sds-purple-secondary: #a884bc;
$sds-purple-tertiary: #900c91;
$sds-red-secondary: #f05674;
$sds-red-tertiary: #cd163f;
$sds-green-secondary: $asc-yellow-0;
//$sds-green-tertiary: #2e9e46;
$sds-green-tertiary: #27863C; //accessible AA
$sds-orange-secondary: #f9a456;
$sds-orange-tertiary: #df6420;
$sds-grey-lightest: #f0f0f0;
$sds-grey-light: #E7E7E7;
$sds-grey-medium: #DDDDDD;
$sds-grey-dark: #D3D3D3;
$sds-grey-darkest: #C8C8C8;

// Additional SAC colours
$sds-grey: #495057;
$sds-grey-1: #979797;
$sds-grey-2: #747474; // accessible AA for text on a white background (e.g. form field placeholder text)
$sds-tw-black: #212529;
$sds-white: #ffffff;
$sds-navy-dark: #000033;


