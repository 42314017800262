@import "../../assets/scss/sds-colours";
@import "../../assets/scss/global";

.public-home {
  //border: 1px solid red;
  margin: 0 -12px;
  overflow: hidden;

  p {
    @include AU-fontgrid(md, default);
  }

  .public-home__content {
    z-index: 100;
  }

  .public-home__banner {
    margin-left: -12px !important;
    margin-right: -12px !important;
    position: relative;
    z-index: 9;
  }

  .public-home__main-content {
    padding-top: 320px;
    margin-top: -240px;
    max-width: $SDS-page-content-max-width;
    position: relative;


      &::before {
        display: block !important;
        position: absolute !important;
        top: 0;
        height: 100%;
        content: "";
        background: $white;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;

        @media (min-width: 1140px){
          width: 1440px;
          left: 0;
          transform: translateX(-100px);
        }

      }

    .public-home__title {
      color: $sds-navy;
    }

    .public-home__description {
      margin-top: 1rem;
      color: $sds-navy;

      a {
        background-image: linear-gradient(180deg, $sds-white, $sds-green-tertiary 0);

        &:hover, &:focus, &:active {
          color: $sds-white;
        }
      }
    }

    .public-home__privacy {
      margin-top: 1rem;
      margin-bottom: 2rem;
    }

    .public-home__card-row {
      margin-top: 3rem;
      margin-bottom: 2rem;
      display: flex;
      flex-wrap: wrap;

      &::before {
        display: none;
      }

      .public-home__card-col {
        margin-bottom: 1rem;

        .public-home__card {
          margin-right: 2rem;
          display: flex;
          flex-direction: column;
          height: 100%;

          .au-card__inner {
            flex: 1 1 auto;
          }

          svg {
            margin-bottom: 1rem;
          }

          .au-card__footer {
            border-top: none;
          }

          .public-home__description {
            color: $sds-navy;
            @include AU-fontgrid( sm, default );
          }
        }
      }
    }
  }

  .public-home__mailing {
    margin-top: 3rem;
    background-color: white;
    max-width: $SDS-page-content-max-width;

    .public-home__dash {
      height: 2px;
      width: 65px;
      background-color: $sds-green-secondary;
      margin-top: 1rem;
      margin-left: 12px;
    }
  }
}