@font-face {
  font-family: simplon;
  src: url('../fonts/SimplonNorm-Light-WebS.eot');
  src: url('../fonts/SimplonNorm-Light-WebS.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SimplonNorm-Light-WebS.woff2') format('woff2'),
  url('../fonts/SimplonNorm-Light-WebS.woff') format('woff'),
  url('../fonts/SimplonNorm-Light-WebS.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: simplon;
  src: url('../fonts/SimplonNorm-LightItalic-WebS.eot');
  src: url('../fonts/SimplonNorm-LightItalic-WebS.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SimplonNorm-LightItalic-WebS.woff2') format('woff2'),
  url('../fonts/SimplonNorm-LightItalic-WebS.woff') format('woff'),
  url('../fonts/SimplonNorm-LightItalic-WebS.ttf') format('truetype');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: simplon;
  src: url('../fonts/SimplonNorm-Regular-WebS.eot');
  src: url('../fonts/SimplonNorm-Regular-WebS.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SimplonNorm-Regular-WebS.woff2') format('woff2'),
  url('../fonts/SimplonNorm-Regular-WebS.woff') format('woff'),
  url('../fonts/SimplonNorm-Regular-WebS.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: simplon;
  src: url('../fonts/SimplonNorm-RegularItalic-WebS.eot');
  src: url('../fonts/SimplonNorm-RegularItalic-WebS.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SimplonNorm-RegularItalic-WebS.woff2') format('woff2'),
  url('../fonts/SimplonNorm-RegularItalic-WebS.woff') format('woff'),
  url('../fonts/SimplonNorm-RegularItalic-WebS.ttf') format('truetype');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: simplon;
  src: url('../fonts/SimplonNorm-Medium-WebS.eot');
  src: url('../fonts/SimplonNorm-Medium-WebS.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SimplonNorm-Medium-WebS.woff2') format('woff2'),
  url('../fonts/SimplonNorm-Medium-WebS.woff') format('woff'),
  url('../fonts/SimplonNorm-Medium-WebS.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: simplon;
  src: url('../fonts/SimplonNorm-MediumItalic-WebS.eot');
  src: url('../fonts/SimplonNorm-MediumItalic-WebS.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SimplonNorm-MediumItalic-WebS.woff2') format('woff2'),
  url('../fonts/SimplonNorm-MediumItalic-WebS.woff') format('woff'),
  url('../fonts/SimplonNorm-MediumItalic-WebS.ttf') format('truetype');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: simplon;
  src: url('../fonts/SimplonNorm-Bold-WebS.eot');
  src: url('../fonts/SimplonNorm-Bold-WebS.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SimplonNorm-Bold-WebS.woff2') format('woff2'),
  url('../fonts/SimplonNorm-Bold-WebS.woff') format('woff'),
  url('../fonts/SimplonNorm-Bold-WebS.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: simplon;
  src: url('../fonts/SimplonNorm-BoldItalic-WebS.eot');
  src: url('../fonts/SimplonNorm-BoldItalic-WebS.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SimplonNorm-BoldItalic-WebS.woff2') format('woff2'),
  url('../fonts/SimplonNorm-BoldItalic-WebS.woff') format('woff'),
  url('../fonts/SimplonNorm-BoldItalic-WebS.ttf') format('truetype');
  font-style: italic;
  font-weight: 700;
}