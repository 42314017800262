.sds-field-list {
    margin-top: 1rem;

    .sds-field-list__label {
        clear: both;
    }

    .sds-field-list__value, .sds-field-list__label {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        min-height: 2rem !important;
    }
}