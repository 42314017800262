@import "src/assets/scss/sds-colours";
@import "src/assets/scss/global";

.sds-page-title {
  text-align: center;
  margin-bottom: 2.5rem;

  .sds-page-title__backButton {
    color: $SDS-color-foreground-text;
    position: absolute;
    margin: 0.2rem;
  }

  .sds-page-title__icon {
    color: $SDS-color-foreground-text;
    padding-bottom: 1rem;
    display: block;
  }

  .sds-page-title__title {
    color: $SDS-color-foreground-text;
    margin: 0.5rem;
    padding-bottom: 1rem;
    padding-top: 0.75rem;
    display: block;
  }

  .sds-page-title__divider {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .sds-page-title__byLine {
    font-size: 1.25rem;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

