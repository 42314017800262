@import "~@sportaus-digital/core/src/scss/core";

.sds-modal {
  .sds-modal__title {
    border-bottom: 1px solid $sds-grey-medium;

    .sds-modal__titleContent {
      margin: 1rem;
      display: flex;

      h3 {
        flex: 1;
      }

      .sds-modal__close {
        flex: 0;
        margin: 0;
        padding: 0;
      }
    }
  }

  .modal-footer {
    border-top: 1px solid $sds-grey-medium;
  }
}