@import "~@sportaus-digital/core/src/scss/core";

.au-btn.sds-button, button.sds-button {
  // Primary as default
  display: inline-block;
  appearance: none;
  font-family: $SDS-font;
  color: $white;
  text-decoration: none;
  border-radius: $SDS-border-radius;
  background-color: $SDS-color-foreground-action;
  border: 2px solid $SDS-color-foreground-action;
  cursor: pointer;
  vertical-align: middle;
  transition: all .1s ease-in;

  &:hover, &:focus, &:active {
    border-radius: $SDS-border-radius;
    outline: 3px solid $SDS-color-foreground-focus;
    outline-offset: 2px;
  }

  &--primary {
    color: $white !important;
  }

  // disabled
  &[disabled], &:disabled, &.disabled { // IE8 doesn’t allow proper selectors
    cursor: not-allowed;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    opacity: 0.6;
  }

  // hover
  &:hover:not(:disabled), &:focus:not(:disabled) {
    background-color: $SDS-color-foreground-action;
    border-color: $SDS-color-foreground-action;
    text-decoration: none;
    color: $SDS-color-background;
  }

  .ie8 &:hover {
    background-color: $SDS-color-foreground-action;
    border-color: $SDS-color-foreground-action;
    color: $SDS-color-background;
    text-decoration: underline;
  }

  /**
	* SECONDARY
	*/
  &.sds-button--secondary {
    color: $white;
    background-color: transparent;
    border-color: $SDS-color-foreground-action;

    &:hover:not(:disabled), &:focus:not(:disabled), &:active:not(:disabled) {
      background-color: transparent;
      border-color: $SDS-color-foreground-text;
      color: $SDS-color-foreground-text;
    }
  }

  /**
    * TERTIARY
    */
  &.sds-button--tertiary {
    color: $SDS-color-foreground-action;
    background-color: transparent;
    border-color: transparent;
    text-decoration: underline;

    &:hover:not(:disabled), &:focus:not(:disabled) {
      background-color: transparent;
      color: $SDS-color-foreground-text;
      border-color: transparent;
      text-decoration: none;
    }
  }

  /**
  * BLOCK
  */
  &.sds-btn--block {
    display: block;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }
}

// Style our wrapped React Link (for routes)
a.sds-button {
  color: $SDS-color-foreground-action;
  background-color: transparent;
  border-color: transparent;
  text-decoration: underline;
  background-image: none !important;

  &:hover:not(:disabled) {
    background-color: transparent;
    color: $SDS-color-foreground-text;
    border-color: transparent;
    text-decoration: none;
  }

  /**
  * BLOCK
  */
  &.sds-btn--block {
    display: block;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }

  &.sds-back-button {
    margin-bottom: -50px;
    color: red;
  }

  &--primary {
    color: $SDS-color-background !important;
    text-decoration: none !important;

    &:hover:not(:disabled), &:focus:not(:disabled) {
      background-color: $asc-blue-0;
      border-color: $asc-blue-0;
      text-decoration: none !important;
      color: $white;
    }

    .ie8 &:hover {
      background-color: $asc-blue-0;
      border-color: $asc-blue-0;
      color: $white;
      text-decoration: underline;
    }
  }
}

.sds-button__icon {
  &--right {
    margin-left: 0.5rem;
  }

  &--left {
    margin-right: 0.5rem;
  }
}
