@import "src/assets/scss/sds-colours";

.sds-field {
  margin-top: 0 !important;
  margin-bottom: 1.5rem !important;

  .sds-field__label {
    display: inline;
    margin-right: 0.5rem;
  }

  .sds-radio-group:nth-child(2) {
    margin-top: 1rem;
  }

  .sds-field__required {
    color: $sds-red-tertiary;
    margin-right: 0.5rem;
  }

  &--warning {
    border-left-color: $sds-orange-secondary !important;

    .sds-field__warning, .au-error-text.sds-field__warning {
      color: $sds-orange-secondary;
    }
  }
}