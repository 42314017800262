@import "../../assets/scss/global";

.sac-footer {
  margin-left: -12px;
  margin-right: -12px;


  .sac-footer__logos {
    margin-top: 2rem;

    .sac-footer__logo {
      display: inline-block;
      margin-bottom: 2.5rem;
    }
  }

  .sac-footer__build {
    vertical-align: middle;
    font-size: small;
    color: $sds-grey;
  }

  .au-footer {
    background-color: $sds-tw-black;
    color: white;
    padding: 0;
    border-top: none;
    margin-top: 2rem;

    .container {
      max-width: $SDS-page-content-max-width;
      margin: 0 auto;
    }

    .au-footer__navigation {
      padding-bottom: 2rem;
      padding-top: 2rem;
      color: white;

      h3 {
        text-transform: uppercase;
        color: $sds-white;
        @include AU-fontgrid(xs, default);
      }

      .au-link-list {
        color: $sds-grey;
        @include AU-fontgrid(xs, default);

        li {
          margin-bottom: 1rem;
        }

        a {
          color: $sds-grey-1;
          text-decoration: none;

          &:hover, &:focus, &:active {
            text-decoration: underline;
          }
        }
      }
    }

    .au-footer__end {
      padding: 1rem;
      border-top: 1px dashed $sds-grey;

      .au-link-list {
        font-size: 14px;

        a {
          color: $sds-white;
          text-decoration: none;

          &:hover, &:focus, &:active {
            text-decoration: underline;
          }
        }

        li {
          &:nth-child(n+2):before {
            width: auto;
            margin-right: .5rem;
            margin-left: 0;
            color: inherit;
            content: "|";
          }
        }
      }
    }
  }
}