@import "../../assets/scss/global";
@import "../../../src/assets/scss/sds-colours";

$sds-panel-height-sm: 10rem !default;
$sds-panel-height-md: 15rem !default;
$sds-panel-height-lg: 20rem !default;

.sds-panel {
  padding: 1rem;
  margin-bottom: 1rem;
  height: 100%;

  background: $sds-white;
  border: 1px solid $sds-grey-lightest;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  &.sds-panel--lg {
    min-height: $sds-panel-height-lg;
    max-height: $sds-panel-height-lg;
    overflow: scroll;
  }

  &.sds-panel--md {
    min-height: $sds-panel-height-md;
    max-height: $sds-panel-height-md;
    overflow: scroll;
  }

  &.sds-panel--sm {
    min-height: $sds-panel-height-sm;
    max-height: $sds-panel-height-sm;
    overflow: scroll;
  }

  &.sds-panel--expand {
    max-height: inherit;
    overflow: inherit;
    position: relative;
  }

  .sds-panel__title {
    margin-bottom: 2rem;
  }

  .sds-panel__body {
    padding-bottom: 3rem;
  }

  .sds-panel__action {

    width: 100%;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: 1.5rem;
    padding-top: 0.25rem;
    position: absolute;
    bottom: 0;

    .sds-button {
      color: $sds-green-tertiary;
      text-decoration: none;

      &--tertiary {
        font-weight: 900;

        &:hover, &:focus, &:active {
          outline: none;
        }
      }
    }
  }

  .sds-btn--block, .au-btn.sds-btn--block {
    border-top: 1px solid $sds-grey-medium;
    text-align: left !important;
    height: 100%;
    padding: 0.7rem 1rem;
    vertical-align: center;
    border-radius: 0px 0px $SDS-border-radius $SDS-border-radius;
    transition: all .3s ease-in;

    &:hover, &:focus, &:active  {
      background-color: $asc-yellow-0 !important;
      color: inherit;
    }

  }
}