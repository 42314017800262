@import "~@sportaus-digital/core/src/scss/core";

.sds-dropdown-menu {
  display: inline-block;
  align-items: center;
  font-size: 1rem;

  .dropdown-toggle {
    &:after {
      display: none;
    }
  }

  .sds-dropdown-menu__buttonContent {
    display: flex;
    align-items: center;
    vertical-align: middle;

    .sds-dropdown-menu__label {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      display: inline-block;
    }
  }

  .sds-dropdown-menu__dropdown {
    margin-top: 8px;
    margin-right: -5px;

    .sds-dropdown-menu__divider {
      border-bottom: 1px solid $sds-grey-light;
      margin-bottom: .5rem;
      padding-bottom: .5rem;
    }

    .sds-dropdown-menuItem {
      &:hover, &:active, &:focus {
        background: $asc-yellow-0;
        box-shadow: none;
        border-color: transparent;
      }
    }
  }

  &--primary {
    .sds-dropdown-menu__button, .btn, .btn-primary, .show > .btn-primary, .show > .btn-primary.dropdown-toggle {
      // Primary as default
      display: inline-block;
      appearance: none;
      font-family: $SDS-font;
      color: $SDS-color-background;
      text-decoration: none;
      border-radius: $SDS-border-radius;
      background-color: $SDS-color-foreground-action;
      border: 2px solid $SDS-color-foreground-action;
      cursor: pointer;
      vertical-align: middle;
      transition: all .1s ease-in;

      &:hover:not(:disabled), &:focus:not(:disabled), &:active:not(:disabled), [aria-expanded=true] {
        background-color: $SDS-color-foreground-action !important;
        color: $SDS-color-background !important;
        border: 2px solid $SDS-color-foreground-action !important;
      }
    }
  }

  &--secondary {
    .sds-dropdown-menu__button, .btn-secondary, .show > .btn-secondary, .show > .btn-secondary.dropdown-toggle {
      color: $SDS-color-foreground-action;
      background-color: transparent;
      border-color: $SDS-color-foreground-action;
      border-width: 2px !important;

      &:hover:not(:disabled), &:focus:not(:disabled), &:active:not(:disabled), [aria-expanded=true] {
        background-color: transparent !important;
        border-color: $SDS-color-foreground-text !important;
        border-width: 2px !important;
        color: $SDS-color-foreground-text !important;
      }
    }
  }

  &.sds-dropdown-menu--tertiary {
    .sds-dropdown-menu__button {
      color: $white;
      background-color: transparent;
      border-color: transparent;
      text-decoration: none;
      outline: none;

      &:hover:not(:disabled), &:focus:not(:disabled), [aria-expanded=true] {
        background-color: transparent;
        color: $white;
        border-color: transparent;
        text-decoration: underline;
      }
    }

    .sds-dropdown-menu__buttonContent {
      .sds-dropdown-menu__icon {
        text-decoration: none;
      }
    }
  }

  button {
    &:hover, &:focus, &:active {
      border-radius: $SDS-border-radius;
      outline: 3px solid $SDS-color-foreground-focus !important;
      outline-offset: 2px;
      box-shadow: none !important;
    }
  }
}