@import "../../assets/scss/sds-colours";
@import "../../assets/scss/global";

.au-header.sac-header {
  padding: 0rem 0;
  border-bottom: 1px solid $sds-grey-darkest;
}

.sac-header {
  margin-left: -12px;
  margin-right: -12px;
  position: relative;

  .container {
    max-width: $SDS-page-content-max-width;
    margin: 0 auto;
    background: white !important;
  }

  .sac-header__brand {
    float: left;
  }

  .sac-header__login {
    float: right;
  }

  .au-header {
    padding: 1rem;
  }

  .env {
    width: 0;
    height: 0;
    border-top: 100px solid ;
    border-right: 100px solid transparent;
    float: left;
    position: absolute;
    left: 0;
    top: 0;

    &--test {
      border-top-color: $sds-orange-tertiary;
      color: white;
    }

    &--dev {
      border-top-color: $sds-blue-secondary;
      color: $sds-navy;
    }

    &--local {
      border-top-color: $sds-green-secondary;
      color: white;
    }

    &--devlocal {
      border-top-color: $sds-green-tertiary;
      color: white;
    }

    .envLabel {
      text-transform: uppercase;
      font-weight: bold;
      color: white;
      position: absolute;
      top: -77px;
      width: 70px;
      left: 0px;
      text-align: center;
      font-size: 16px;
      transform: rotate(-45deg);
      display: block;
    }
  }
}