@import "../../../src/assets/scss/sds-colours";





  //collapsing left container using media query
@media (max-width: 900px) {

  #sidebar-small {
    display: block !important;
  }

  #sidebar-full {
    display: none !important;
  }
}

#sidebar-small {
  display: none;
}

#sidebar-full {

}
.sds-sidebar {
  background: $sds-white;
  display: block;
  float: left;
  height: 100vh;
  width: 250px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  overflow: hidden;
  margin-right: 1rem;
  position: unset;

  //collapsing left container using media query
  @media (max-width: 900px) {
    width: 50px;
    
    // li:not(:first-child) {
    //   display: none;
    // }
    // #menu-burger {
    //   display: list-item !important;
    // }

  }

  #sidebar-small {
    display: none;
  }

  #sidebar-full {

  }

  #sidebar-small-nav {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1078;
    background-color: #fff;
    display: none;
  }

  .sidebar-small-nav-show {
    display: block!important;
  }

  // Sidebar
  .sds-sidebar__nav {
    .text-align-right {
      text-align: right;
    }
    .sds-sub-menu {
      .sds-menu-item {
        .sds-menu-item__button {
          background-color: $sds-white;
          padding-left: 1em;
          color: $sds-navy;
        }
      }
    }
    .sds-menu-item {
      .sds-menu-item__button {
        background: $sds-white;
        border-radius: 0;
        color: $asc-blue-0;
        text-decoration: none;
        display: table;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 100%;
        cursor: pointer;
        transition: all .1s ease-in;
        white-space: nowrap;

        &:hover, &:focus, &:active, &--active {
          background: $asc-yellow-0;
          color: $asc-blue-0;
          outline: none;
        }

        .sds-menu-item__icon {
          display: inline-block;
          font-size: 1.5rem;
          vertical-align: middle;
          width: 3rem;
          text-align: center;

          &.fa-pull-right {
            margin-left: 0;
          }
        }
        @media (max-width: 900px) {
          .sds-menu-item__icon {
            width: 2rem;
          }
        }

        .sds-menu-item__text {
          margin-left: 0.4rem;
        }
      }

      .sds-nav__text {
        display: inline-block;
        margin-left: .5rem;
      }
    }
  }
}


