@import "~@sportaus-digital/core/src/scss/core";

$sac-accordion-button-color: $sds-navy !default;
$sac-accordion-button-focus-outline: 3px solid $sds-green-secondary !default;

.sds-accordion {
  margin-top: 2rem;

  [data-reach-accordion] {
    button[data-reach-accordion-button] {
      color: $sac-accordion-button-color;
      padding-left: 0;
      background-color: transparent;
      border-color: transparent;
      text-decoration: underline;

      &:focus {
        outline: $sac-accordion-button-focus-outline;
        outline-offset: 2px;
      }
    }
  }

  .sds_accordion__indicator--open, .sds_accordion__indicator--closed {
    font-size: 0.8rem;
    display: inline-block;
    text-decoration: none;
    margin-left: 0.5rem;
  }

  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  [data-reach-accordion-panel] {
    border-radius: 5px;
    animation: slide-down 0.3s ease;
  }
}