@import "~@sportaus-digital/core/src/scss/colours";
@import "./_fonts.scss";

//
// Sport Connect global variables
//

// SDS variables
$SDS-page-max-width: 1540px; // Max width of web page
$SDS-page-content-max-width: 1140px; // Max width of content within web page - static content
$SDS-page-content-admin-max-width: 1000px; // Max width of content within web page - admin content

$SDS-color-background: $asc-blue-0;
$SDS-color-foreground-action: $asc-blue-0;
$SDS-color-foreground-focus: $asc-blue-0;
$SDS-color-foreground-text: $white;
$SDS-font: Simplon, Helvetica Neue, Arial, sans-serif;

$SDS-border-radius: 5px;

$sac-accordion-button-color: $sds-navy;
$sac-accordion-button-focus-outline: 3px solid $sds-green-secondary;

//
// Font Awesome integration
//
@import "~@fortawesome/fontawesome-free/css/all.css";

//
// Bootstrap integration
//
@import "./_bootstrap-customisations.scss";

//
// DTA design system integration
//
@import "./_dta-customisations.scss";

//
// Sport Connect global styles
//
body {
  font-family: $SDS-font;
  background: $sds-grey-lightest;
  margin: 0;

  .skippy {
    display: block;
    padding: 1rem;
    color: #ffffff;
    text-align: center;
    background-color: #E0E0E0;
    transition: all 0.3s ease-in;
  }

  .skippy:hover, .skippy:focus, .skippyactive {
    background-color: #333333;
    color: #9ECF7C;
  }


  .sds-content {

    a:not(.pill) {
      color: $asc-blue-0;
      background-image: linear-gradient(180deg, $sds-white, $sds-green-secondary 0);
      background-repeat: no-repeat;
      background-position: 0 1em;
      transition: background-position .1s ease-out, color .1s ease-out;

      &:hover, &:focus, &:active {
        color: $white;
        background-position: 0 0;
        text-decoration: none;
      }
    }

    a:not(.pill).sds-button {
      transition: all .1s ease-in;
      color: $asc-blue-0;
      text-decoration: underline;


      &:hover, &:focus, &:active {
        color: $white;
        text-decoration: none;
        outline: 3px solid $asc-yellow-0;
        outline-offset: 2px;
      }

    }

  }

  .au-grid {
    background: white;
  }

  .container-fixed-width {
    max-width: $SDS-page-max-width;
    margin: 0 auto;
  }


  // Containers
  // Self service
  .sds-container__self-service {
    margin-left: -12px !important;
    margin-right: -12px !important;
    position: relative !important;
  }


  .container-right {
    width: auto !important;
    padding-left: 12px;
    padding-right: 12px;

    .container-content {
      max-width: $SDS-page-content-admin-max-width;
      padding-top: 2rem;
    }
  }

  .container-content {
    max-width: $SDS-page-content-max-width;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .identity-doc-sample {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .duplicate-list {
    .duplicate-list-item {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      &:first-child {
        margin-top: 1.5rem;
      }

      &:last-child {
        margin-bottom: 1.5rem;
      }

      &:not(:last-child) {
        padding-bottom: 1rem;
        border-bottom: 1px solid $sds-grey-light;
      }
    }
  }

  // Typography
  // Sizing
  p {
    @include AU-fontgrid(sm, default);
  }

  // Spacing
  h1, h2, p {
    @include AU-space(margin, 1.6 unit 0);
  }

  h1, h2, h3, h4, h5, h6 {
    color: $sds-navy-dark;
    font-weight: 500;
  }

  .panel-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > div {
      padding-bottom: 2rem;
    }
  }
}

.oauth-card {
  .sds-field-list__value {
    overflow-wrap: break-word;
  }
}

.undecorated-list {
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
  padding-left: 0;

  li:not(:first-child) {
    margin-top: 0.75rem;
  }
}

.column-list-2 {
  column-count: 2;
}

.user-identities {
  .groupedIdHeading {
    margin-left: 0.5rem;

    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
}

.hide {
  opacity: 0;
  max-height: 0;
}

.fade-in {
  max-height: 300px;
  opacity: 1;
  transition: opacity 0.5s ease-in;
}