@import "~@sportaus-digital/core/src/scss/core";

.sds-card {
  margin: 0.5rem;
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid $sds-grey-darkest;
  overflow: hidden;

  dl {
    flex: 1;
  }

  &--no-border {
    border: none !important;
  }

  .sds-card__genericButtonRow {
    margin-top: 1rem;
    width: 100%;
    text-align: right;

    * {
      display: inline-block;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .sds-card__doubleButtonRow {
    margin-top: 1rem;
    width: 100%;
    display: flex;

    .sds-card__leftContainer {
      width: 100%;
      text-align: left;
    }

    .sds-card__rightContainer {
      width: 100%;
      text-align: right;
    }

    .sds-button, a, button {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}