@import "../../assets/scss/_sds-colours.scss";

.id-card {
  margin: 0.5rem;
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  dl {
    flex: 1;
  }

  &--no-border {
    border: none !important;
  }

  &--LINKED {
    border: 1px solid $sds-grey-darkest;
  }

  &--PENDING {
    border: 1px solid $sds-orange-tertiary;
    background-color: $sds-grey-lightest;
  }

  &--DECLINED, &--REVOKED {
    border: 1px solid $sds-grey-light;

    h1, h2, h3, h4, .sds-field-list__label, .sds-field-list__value {
      opacity: 0.5;
    }
  }

  .pending {
    color: $sds-orange-tertiary;
    margin-left: 1rem;
  }

  .id-card__statusLabel {
    color: inherit;
    opacity: 1;
  }

  .id-card__status {
    font-weight: 500;
    opacity: 1;

    &--LINKED {
      color: $sds-green-tertiary;
    }

    &--PENDING {
      color: $sds-orange-tertiary;
    }

    &--REVOKED, &--DECLINED {
      color: $sds-red-tertiary;
    }
  }

  .id-card__singleButtonRow {
    margin-top: 1rem;
    width: 100%;
    text-align: right;
  }

  .id-card__doubleButtonRow {
    margin-top: 1rem;
    width: 100%;
    display: flex;

    .id-card__leftContainer {
      width: 100%;
      text-align: left;
    }

    .id-card__rightContainer {
      width: 100%;
      text-align: right;
    }

    .sds-button {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}

.identity-summary {
  li {
    padding: 0;
    margin: 0;
  }

  .sds-button--tertiary {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 1rem;
    padding: 0;
  }
}