.sac-org-summary {
    .sac-org-summary__name {
        font-weight: bold;
    }

    &--compact {
        .sac-org-summary__abn,
        .sac-org-summary__acn {
            display: inline-block;
            margin-right: 0.5rem;
        }
    }
}