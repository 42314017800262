@import "src/assets/scss/_sds-colours";

.sds-form {
  .sds-form__actions {
    margin-top: 3rem;
    display: flex;

    .sds-form__extraActions {
      flex: 1;
    }

    .sds-form__standardActions {
      text-align: right;
      flex: 1;

      .sds-form__cancel {
        &--route {
          margin-right: 2rem;
        }
      }
    }

    .sds-form__formError {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
}