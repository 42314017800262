@import "src/assets/scss/sds-colours";

html, body, #root {
  height: 100%;
}

.full-page-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .full-page-loader__logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    width: 70%;
  }
}