@import "../../assets/scss/_sds-colours.scss";

// Overrides of !default variables from DTA, BEFORE the ~@gov.au/* imports
$AU-color-background: white;
$AU-color-foreground-action: $sds-navy;
$AU-color-foreground-focus: $sds-green-secondary;
$AU-color-foreground-text: $sds-navy;
$AU-font: $SDS-font;


$AU-logo-width: 8unit;
$AU-logo-width-sm: 12unit;


// Import the global and module scss files from each DTA component that we use
// Make sure you also import these files from secondary dependencies
// (e.g. component A depends on B, therefore import B's global and module files
// even though we don't directly reference them - A does)
@import "~@gov.au/core/lib/sass/globals";
@import "~@gov.au/core/lib/sass/module";
@import "~@gov.au/body/lib/sass/module";
@import "~@gov.au/grid-12/lib/sass/module";
@import "~@gov.au/buttons/lib/sass/module";
@import "~@gov.au/card/lib/sass/module";
@import "~@gov.au/control-input/lib/sass/module";
@import "~@gov.au/footer/lib/sass/module";
@import "~@gov.au/form/lib/sass/module";
@import "~@gov.au/header/lib/sass/module";
@import "~@gov.au/link-list/lib/sass/module";
@import "~@gov.au/table/lib/sass/module";
@import "~@gov.au/text-inputs/lib/sass/module";
@import "~@gov.au/page-alerts/lib/sass/module";

// Add GLOBAL overrides DTA of styles here, AFTER the ~@gov.au/* imports
// If you only want to override a DTA style in the context of one of our components, add the overrides
// to the component's own .scss file and scope them appropriately so the styles do not bleed outside
// the component


.au-page-alerts {
  margin-bottom: 0.5rem;
}

.au-body p {
  max-width: inherit;
}