@import "~@sportaus-digital/core/src/scss/core";
@import "~@fortawesome/fontawesome-free/css/all.css";

.sds-alert {
  position: relative;
  opacity: 1;

  .sds-alert__dismiss {
    position: absolute;
    top: 0.25rem;
    right: 0.75rem;

    .sds-alert__dismissBtn {
      border: none;
      background: none;

      &:hover {
        border: 2px solid $sds-green-secondary;
      }
    }
  }

  &.fade-out {
    transition: opacity ease-out 0.5s;
    opacity: 0;
  }

  &:before {
    font-family: "Font Awesome 5 Free";
    display: inline-block !important;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    background: none !important;
    border-radius: 12px !important;
    font-size: 1.5rem;
    color: white;
    content: none !important;
    background-repeat: inherit !important;
    margin-top: -17px !important;
  }

  &:after {
    background: none !important;
  }

  &--error:before {
    content: "\f057" !important;
  }

  &--warning:before {
    content: "\f06a" !important;
  }

  &--info:before {
    content: "\f05a" !important;
  }

  &--success:before {
    content: "\f058" !important;
  }
}