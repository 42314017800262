@import "~@sportaus-digital/core/src/scss/core";

$AU-color-background: white;
$AU-color-foreground-action: $sds-navy;
$AU-color-foreground-focus: $sds-green-secondary;
$AU-color-foreground-text: $sds-navy;
$AU-font: $SDS-font;


$AU-logo-width: 8unit;
$AU-logo-width-sm: 12unit;

.sds-text-field, .sds-text-area {
  &:focus {
    outline: 2px solid $sds-green-secondary !important;
    outline-offset: 2px;
    border-radius: 0;
    background-color: white;
    box-shadow: none;
    transition: none;
  }
}

.sds-select, .sds-autocomplete, .sds-multitextinput {
  .sds-select__control, .sds-autocomplete__control, .sds-multitextinput__control {
    border: 1px solid $sds-grey-1;
    min-height: 2.875rem;

    &:hover {
      border: 1px solid $sds-grey-1;
    }

    &--is-focused {
      outline: 2px solid $sds-green-secondary !important;
      outline-offset: 2px;
      border-radius: 0;
      background-color: white;
      box-shadow: none;
      transition: none;
    }

    .sds-select__placeholder {
      color: $sds-grey-2;
    }
  }
}

.sds-autocomplete {

  .sds-autocomplete__menu-list {
    padding: 0;
  }

  .sds-autocomplete__option {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .sds-autocomplete__emptyWrapper {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 12px;
    margin-top: 2px;
    margin-bottom: 2px;

    .sds-autocomplete__typeToSearch, .sds-autocomplete__defaultNoMatch {
      font-style: italic;
    }

    &--with-component {
      padding: 0;
      margin: 0;
    }
  }

  .sds-autocomplete__indicator-separator {
    display: none;
  }

  .sds-autocomplete__placeholder {
    color: $sds-grey-2;
  }
}

.sds-multitextinput {
  .sds-multitextinput__value-container {
    display: block;

    .sds-multitextinput__multi-value {
      width: max-content;
    }
  }

  .sds-multitextinput__placeholder {
    color: $sds-grey-2;
  }
}

.sds-radio {
  .au-control-input__input[type=radio] + .au-control-input__text:before {
    border: 2px solid black !important;
    background-image: none !important;
    background-color: white !important;
    width: 2.275rem !important;
    height: 2.275rem !important;
  }

  .au-control-input__input[type=radio]:checked + .au-control-input__text:after {
    background-image: none;
    background-color: black;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    left: 3px;
    top: 3px;
  }
}

.sds-text-area.au-text-input, .sds-text-field.au-text-input {
  border-width: 1px;
  border-color: $sds-grey-1;
}