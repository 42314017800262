@import "~@sportaus-digital/core/src/scss/core";

.sds-spinner {
  &--block {
    display: block;
    text-align: center;
  }

  &--inline {
    display: inline-block;
  }

  .sds-spinner__spinner {
    &--inline {
      margin-right: 0.5rem;
    }

    &--block {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0.5rem;
    }

    $offset: 187;
    $duration: 1.4s;

    animation: rotator $duration linear infinite;

    @keyframes rotator {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(270deg);
      }
    }

    .path {
      stroke-dasharray: $offset;
      stroke-dashoffset: 0;
      transform-origin: center;
      animation: dash $duration ease-in-out infinite,
      colors ($duration*4) ease-in-out infinite;
    }

    @keyframes colors {
      0% {
        stroke: $sds-red-tertiary;
      }
      25% {
        stroke: $sds-orange-tertiary;
      }
      50% {
        stroke: $sds-yellow;;
      }
      75% {
        stroke: $sds-green-tertiary;
      }
      100% {
        stroke: $sds-blue-tertiary;
      }
    }

    @keyframes dash {
      0% {
        stroke-dashoffset: $offset;
      }
      50% {
        stroke-dashoffset: $offset/4;
        transform: rotate(135deg);
      }
      100% {
        stroke-dashoffset: $offset;
        transform: rotate(450deg);
      }
    }
  }
}