@import "../../assets/scss/_sds-colours.scss";

.sds-consent__consent {
  margin-bottom: 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid $sds-grey-light;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }

  .sds-consent__header {
    display: flex;
    margin-bottom: 1rem;
    font-weight: bold;

    .sds-consent__heading {
      flex: 1;
    }

    .sds-consent__timestamp {
      flex: 0;
      text-align: right;
      white-space: nowrap;
    }

    .sds-consent__status {
      margin-left: 1rem;
    }
  }

  .sds-consent__data-list, .sds-consent__purpose-list {
    margin-top: 0.25rem;
  }

  h4.sds-consent__heading {
    margin-top: 1rem;
    font-weight: bold;
  }
}