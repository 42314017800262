@import "~@sportaus-digital/core/src/scss/core";

$sds-table-row-highlight-colour: $sds-grey-lightest !default;

.sds-data-table {
  .sds-data-table__spinner {
    height: 2rem;
  }

  .au-table {
    border-bottom: 1px solid $sds-grey-darkest;

    .au-table__row {
      .au-btn.au-btn--tertiary, a.au-btn.au-btn--tertiary {
        padding: 0;
        margin-right: 0.5rem;
      }

      .au-table__cell {
        vertical-align: top;
        border: none;
        padding: 1rem;
      }
    }

    .au-table__caption {
      caption-side: top;
    }
  }

  .sds-data-table__header {
    vertical-align: top;
    font-weight: bold;

    .au-btn {
      margin: 0;
      padding: 0;
      font-weight: bold;
      text-decoration: none;
    }

    .sds-data-table__header--sortable {
      &:after {
        font-family: "Font Awesome 5 Free";
        content: ' \f0dc';
      }
    }

    .sds-data-table__header--asc {
      &:after {
        font-family: "Font Awesome 5 Free";
        content: ' \f0de';
      }
    }

    .sds-data-table__header--desc {
      &:after {
        font-family: "Font Awesome 5 Free";
        content: ' \f0dd';
      }
    }

    .sds-data-table__filter {
      display: block;
      height: 2rem;
      border: 1px solid $sds-grey-1;
      border-radius: 4px;
      width: 100%;

      &:focus {
        outline: 2px solid $sds-green-secondary !important;
        outline-offset: 2px;
        border-radius: 0;
        background-color: white;
        box-shadow: none;
        transition: none;
      }
    }

    .sds-data-table__filter-label {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }
  }

  .sds-data-table__row--with-hover {
    &:hover {
      background-color: $sds-table-row-highlight-colour;
    }
  }

  .sds-data-table__table {
    .sds-button--tertiary {
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;

      &:hover:not(:disabled), &:focus:not(:disabled) {
        background-color: transparent;
        color: $SDS-color-foreground-text;
        border-color: transparent;
        text-decoration: none;
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}