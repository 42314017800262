@import "~@sportaus-digital/core/src/scss/core";

.pill {
  display: inline-block;
  padding: 0.5rem 2rem;
  border-radius: 30px;
  background-color: $sds-yellow;
  text-decoration: none;
  color: $sds-navy-dark;

  &:hover, &:focus {
    text-decoration: none;
  }
}