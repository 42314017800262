@import "src/assets/scss/sds-colours";

.invitation-indicator {
  color: $sds-orange-secondary;
  margin-left: 1rem;
}

.organisation-affiliations {
  .sds-panel {
    .sds-panel__title {
      margin-bottom: 1rem;
    }
  }
  .noAffiliationsMessage {
    width: 100%;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 6rem;
  }
}

.affiliation-details {
  h3 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .buttonGroup {
    text-align: right;
    margin-top: 5rem;

    .sds-button {
      margin-left: 1rem;
    }
  }
}

.affiliation-change-confirmation {
  .affiliation-confirmation__connectionList {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    font-weight: bold;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.add-affiliation {
  .sds-form__actions {
    margin-top: 10rem;
  }

  .org-select-option {
    .org-select-option__name {
      font-weight: bold;
    }

    .org-select-option__exists {
      margin-top: 0.5rem;
    }
  }

  .no-matching-org {
    background-color: $sds-gold;
    padding: 1rem;

    .no-matching-org__header {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  }

  .sac-org-summary {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
}

.organisation-invitation-add {
  .sds-form__actions {
    display: block;
  }
}

.affiliations-summary {
  h3 {
    margin-top: 2rem;
  }

  ul {
    list-style: none;
    margin-top: 1rem;
    margin-left: 0;
    padding-left: 0;

    li {
      display: flex;

      .text {
        flex: 1;
      }
    }
  }
}